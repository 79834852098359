import styled from 'styled-components';
import {
	setColors,
	media,
	mediaQuery,
	setFontSize,
} from '../../../../utils/helpers';

export const BlueSection = styled.section`
	background-color: ${setColors.lightBlue};
	display: grid;
	justify-items: center;
	padding-top: 0px;
	padding-bottom: 65px;

	${media.tablet`
		padding-top: 50px;
		padding-bottom: 105px;
	`}
`;

export const localWrapper = styled.div`
	padding-top: 60px;

	h2,
	p {
		color: ${setColors.white};
	}
`;

export const ImageWrapper = styled.div`
	margin: 30px 0;
	@media (max-width: 1161px) {
		padding: 0 15px;
	}
	@media (max-width: 768px) {
		padding: 0 15px;
	}
`;

export const MainImageCardWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 30px;
	margin-bottom: 60px;

	.imageCardTitle,
	.imageCardSubtitle {
		color: ${setColors.white};
	}
	a:visited {
		color: ${setColors.themeBlue};
	}

	${mediaQuery.tabletS`
		display: none;
	`}
`;

export const SliderContainer = styled.div`
	display: none;

	.title,
	.subtitle {
		color: ${setColors.white};
	}

	.slick-list {
		margin: 0 auto;
	}

	.slick-dots {
		bottom: -45px;
	}

	.slick-dots li button:before {
		font-size: ${setFontSize.smallest};
	}

	.slick-dots li button:before {
		color: #bedbfc;
	}

	.slick-dots li.slick-active button:before {
		color: ${setColors.themeBlue};
		opacity: 1;
	}

	${mediaQuery.tabletS`
		display: block;
		justify-content: center;
		align-items: center;
		margin: 45px 0 90px 0;

		.cardWrapper{
			max-width: 100%;
			display: flex !important;
		}

		.imageContainer{
			overflow: unset;
		}

		.slick-list {
			max-width: 420px;
		}

		.slick-arrow{
			display: none !important;
		}
	`}

	${mediaQuery.mobileM`
		.slick-list {
			max-width: 360px;
			max-height: 430px;
		}
	`}

	${mediaQuery.mobile430`
		.slick-list {
			max-width: 300px;
			max-height: 400px;
		}
	`}

	${mediaQuery.mobileS`
		.slick-list{
			max-width: 280px;
			max-height: 400px;
		}
	`}
`;
