import React from 'react';
import { CustomerStoryWrapper } from '../../enterprise-chatbot/styles';
import CustomerStory from '../CustomerStory/CustomerStory';

const CustomerStoryFragmentWrapper = ({ data, paddingTop }) => {
	return (
		<CustomerStoryWrapper paddingTop={paddingTop}>
			<CustomerStory
				customerStoryImage={data.mainImage}
				customerStoryLogo={data.logoImage}
				customerStoryText={data.customersComment?.customersComment}
				customerName={data.customerName}
				customerTitle={data.customerDesignation}
				cusomterSlug={data.linkUrl}
				linkLabel={data.linkLabel}
			/>
		</CustomerStoryWrapper>
	);
};

export default CustomerStoryFragmentWrapper;
