import styled from 'styled-components';
import { mediaQuery } from '../../../utils/helpers';

export const InfoBoxWrapper = styled.div``;

export const CustomersWrapper = styled.div`
	${mediaQuery.tabletXL`
		padding-top: 30px;
	`}
`;

export const CustomerStoryWrapper = styled.div`
	padding-top: ${props => props.paddingTop || '100px'};
	margin-top: 50px;

	${mediaQuery.mobileM`
		padding-top: 20px;
	`}
`;

export const SectionTextBoxWrapper = styled.div`
	padding-top: 50px;
	${mediaQuery.mobileM`
		padding-top: 0px;
	`}
`;

export const FeatureBoxWrapper = styled.div`
	padding-top: 70px;
	${mediaQuery.mobileM`
		padding-top: 0px;
	`}
`;

export const SectionText2BoxWrapper = styled.div`
	padding-top: 100px;
	${mediaQuery.mobileM`
		padding-top: 20px;
	`}
`;

export const ToolkitImageHolderWrapper = styled.div`
	margin-top: -30px;
	${mediaQuery.mobileM`
		margin-top: -80px;
	`}
`;

export const ImgWrapper = styled.div`
	padding-top: 30px;
`;

export const CTAWrapper = styled.div`
	padding-top: 100px;
	margin-top: 50px;
	padding-bottom: 20px;
	${mediaQuery.mobileM`
		padding-top: 50px;
		margin-top: 20px;
		padding-bottom: 12px;
	`}
`;
