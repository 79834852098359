import React from 'react';
import * as S from './styles';
import { IconNext } from '../global/icons';

const ImageCardWrapper = ({ cards, BgColor }) => {
	return (
		<>
			{cards.map(card => (
				<S.ImageCardWrapper BgColor={BgColor} className="imageCardWrapper">
					<S.Container>
						<S.ImageCardTitle className="imageCardTitle">
							{card.title}
						</S.ImageCardTitle>
						<S.ImageCardSubtitle className="imageCardSubtitle">
							{card.subtitle.subtitle}
						</S.ImageCardSubtitle>
						{card.link && (
							<S.ImageCardLinkWrapper className="imageCardLink">
								<a href={card.link} rel="noopener noreferrer">
									<span>{card.linkText}</span>
								</a>
								<IconNext height={14} width={14} />
							</S.ImageCardLinkWrapper>
						)}
					</S.Container>
					<S.ImageContainer className="imageContainer">
						<S.ImageCardImageWrapper
							className="imageCardImageWrapper"
							src={card.image.fluid.srcWebp || card.image.fluid.src}
						/>
					</S.ImageContainer>
				</S.ImageCardWrapper>
			))}
		</>
	);
};

export default ImageCardWrapper;
