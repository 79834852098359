import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import Slider from 'react-slick';
import SectionTextBox from '../../global/section-textbox/SectionTextBox';
import WrapperOne from '../../global/wrappers/WrapperOne';
import { setColors } from '../../../../utils/helpers';
import { LinkWrapper } from '../about-chatbots/styles';
import { IconNext } from '../../global/icons';
import ImageCardWrapper from '../../ImageCardWrapper/ImageCardWrapper';
import SlickCSS from '../../about-us-page/slick-css';
import * as ImageCardStyles from '../../ImageCardWrapper/styles';
import ImageWrapper from '../../global/ImageWrapper/ImageWrapper';

const sliderSettings = {
	dots: true,
	fade: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	focusOnSelect: true,
	cssEase: 'linear',
};

const ChatbotBuilder = ({
	section7Subtitle,
	section7Title,
	section7Image,
	section7Cards,
	section7Link,
}) => {
	return (
		<WrapperOne setBgColor={setColors.lightBlack} maxWidth={'1200px'}>
			<S.localWrapper>
				<SectionTextBox
					title={section7Title}
					subtitle={section7Subtitle}
					button={false}
					link={false}
					largeHeading="true"
					isHomePage={true}
				/>
			</S.localWrapper>
			{section7Link.linkText && section7Link.linkUrl && (
				<LinkWrapper>
					<a href={section7Link.linkUrl} rel="noopener noreferrer">
						<span>{section7Link.linkText}</span>
					</a>
					<IconNext height={14} width={14} />
				</LinkWrapper>
			)}
			<S.ImageWrapper>
				<ImageWrapper
					image={section7Image}
					imageStyles={{ maxWidth: '1200px', margin: '0 auto' }}
				/>
			</S.ImageWrapper>
			<S.MainImageCardWrapper>
				<ImageCardWrapper cards={section7Cards} BgColor={'#212121'} />
			</S.MainImageCardWrapper>
			<S.SliderContainer>
				<SlickCSS />
				<Slider {...sliderSettings}>
					{section7Cards.map(card => (
						<ImageCardStyles.ImageCardWrapper
							BgColor={'#212121'}
							className="cardWrapper"
						>
							<ImageCardStyles.Container>
								<ImageCardStyles.ImageCardTitle className="title">
									{card.title}
								</ImageCardStyles.ImageCardTitle>
								<ImageCardStyles.ImageCardSubtitle className="subtitle">
									{card.subtitle.subtitle}
								</ImageCardStyles.ImageCardSubtitle>
								{card.link && (
									<ImageCardStyles.ImageCardLinkWrapper className="">
										<a href={card.link} rel="noopener noreferrer">
											<span>{card.linkText}</span>
										</a>
										<IconNext height={14} width={14} />
									</ImageCardStyles.ImageCardLinkWrapper>
								)}
							</ImageCardStyles.Container>
							<ImageCardStyles.ImageContainer className="imageContainer">
								<ImageCardStyles.ImageCardImageWrapper
									className=""
									src={card.image.fluid.srcWebp || card.image.fluid.src}
								/>
							</ImageCardStyles.ImageContainer>
						</ImageCardStyles.ImageCardWrapper>
					))}
				</Slider>
			</S.SliderContainer>
		</WrapperOne>
	);
};

ChatbotBuilder.defaultProps = {
	button: true,
};

ChatbotBuilder.propTypes = {
	button: PropTypes.bool,
};
export default ChatbotBuilder;
