import { graphql } from 'gatsby';
import React from 'react';
import CTA from '../components/global/CTA/CTA';
// import Customers from '../components/global/customers/Customers';
import Layout from '../components/global/layout/Layout';
import AboutChatbots from '../components/home-page/about-chatbots/AboutChatbots';
import ChatbotBuilder from '../components/home-page/chatbot-builder/ChatbotBuilder';
import Features from '../components/home-page/features/Features';
import Header from '../components/home-page/header/Header';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import SEO from '../components/global/seo';
import { HomepageCTAWrapper } from '../components/home-page/CtaWrapper/CTAWrapper';
import { setColors } from '../../utils/helpers';
import {
	CustomerToolkitSlider,
	UsecasesToolkitSlider,
} from '../components/global/ToolKit/ToolkitSlider';
import { settings } from './bot-builder';
import SectionTextBox from '../components/global/section-textbox/SectionTextBox';
import ImageWrapper from '../components/global/ImageWrapper/ImageWrapper';
import { EllipseWrapper, Ellipse } from '../components/home-page/header/styles';
import {
	ArrowWrapper,
	LinkWrapper,
	LocalWrapper,
	TitleWrapper,
	UseCaseSliderWrapper,
} from '../components/home-page/about-chatbots/styles';
import { IconNext } from '../components/global/icons';
import ArrowVectorDown from '../svg/arrowDown.svg';
import UseCaseTitle from '../svg/useCaseTitle.svg';

const index = ({ data }) => {
	const {
		section1Link1,
		section1Link2,
		section1Linktext1,
		section1Linktext2,
		section1Subtitle,
		section1Title,
		heroSectionMedia,
		section2Title,
		section2Images,
		section3subtitleNew,
		section3Title,
		section3Link,
		section3Cards,
		section3Image,
		customerTestimonials,
		section4Title,
		section4Subtitle,
		section4Link,
		section4Image,
		usecases,
		chatbotResultStatistics,
		wotnotChatbotUses,
		section7Subtitle,
		section7Title,
		section7Link,
		section7Image,
		section7Cards,
		section8Subtitle,
		section8Title,
		section8Image,
		section9Link,
		section9LinkText,
		section9Subtitle,
		section9Title,
		section9Image,
		section11Subtitle,
		section11Title,
		seoTitle,
		seoDescription,
		seoArticle,
		seoImage,
		seoJsonSchema,
	} = data.allContentfulHomePage.nodes[0];
	return (
		<Layout>
			<SEO
				title={seoTitle}
				description={seoDescription}
				article={seoArticle}
				image={seoImage}
				seoJsonSchema={seoJsonSchema}
			/>
			<WrapperOne
				setBgColor={setColors.lightBlack}
				applyBackgroundImage
				disableBottomMargin
			>
				<Header
					section1Link1={section1Link1}
					section1Link2={section1Link2}
					section1Subtitle={section1Subtitle}
					section1Title={section1Title}
					section1Linktext1={section1Linktext1}
					section1Linktext2={section1Linktext2}
					heroSectionMedia={heroSectionMedia}
				/>
			</WrapperOne>
			<CustomerToolkitSlider
				title={section2Title}
				settings={settings}
				toolkits={section2Images}
			/>
			<EllipseWrapper>
				<Ellipse />
			</EllipseWrapper>
			<AboutChatbots
				section3Subtitle={section3subtitleNew.internal.content}
				section3Title={section3Title}
				section3Image={section3Image}
				section3Link={section3Link}
				section3Cards={section3Cards}
				customerTestimonials={customerTestimonials}
				section4Title={section4Title}
				section4Subtitle={section4Subtitle}
				section4Image={section4Image}
				section4Link={section4Link}
				chatbotResultStatistics={chatbotResultStatistics}
				wotnotChatbotUses={wotnotChatbotUses}
				section7Subtitle={section7Subtitle}
				section7Title={section7Title}
				section7Image={section7Image}
			/>
			<UseCaseSliderWrapper>
				<WrapperOne
					maxWidth={'100vw'}
					setBgColor={setColors.bottleGreenColor}
					disableBottomMargin
					contentClass={'sliderClass'}
				>
					<div
						style={{
							textAlign: 'center',
							marginBottom: '60px',
							position: 'relative',
						}}
					>
						<TitleWrapper>
							<UseCaseTitle />
						</TitleWrapper>
						<ArrowWrapper>
							<ArrowVectorDown />
						</ArrowWrapper>
						<UsecasesToolkitSlider toolkits={usecases} />
						<LinkWrapper>
							<a href={'/use-cases'} rel="noopener noreferrer">
								<span>See all</span>
							</a>
							<IconNext height={14} width={14} />
						</LinkWrapper>
					</div>
				</WrapperOne>
			</UseCaseSliderWrapper>
			<ChatbotBuilder
				section7Subtitle={section7Subtitle}
				section7Title={section7Title}
				section7Image={section7Image}
				section7Link={section7Link}
				section7Cards={section7Cards}
			/>
			<LocalWrapper>
				<WrapperOne setBgColor={setColors.white} maxWidth={'1200px'}>
					<SectionTextBox
						title={section8Title}
						subtitle={section8Subtitle}
						isHomePage={true}
					/>
					<ImageWrapper
						image={section8Image}
						imageStyles={{ marginTop: '10px', borderRadius: '0px' }}
						otherStyles={{ maxWidth: '100vw', margin: '0 auto', width: '100%' }}
					/>
					<Features
						section9Link={section9Link}
						section9LinkText={section9LinkText}
						section9Subtitle={section9Subtitle}
						section9Title={section9Title}
						section9Image={section9Image}
					/>
				</WrapperOne>
			</LocalWrapper>
			<WrapperOne disableBottomMargin="true" maxWidth={'1200px'}>
				<HomepageCTAWrapper>
					<CTA title={section11Title} subtitle={section11Subtitle} />
				</HomepageCTAWrapper>
			</WrapperOne>
		</Layout>
	);
};

export const query = graphql`
	{
		allContentfulHomePage {
			nodes {
				section1Link1
				section1Link2
				section1Linktext1
				section1Linktext2
				section1Subtitle
				section1Title
				heroSectionMedia {
					...asset
				}
				section2Images {
					fluid {
						...GatsbyContentfulFluid
					}
					title
				}
				section2Title
				section3Image {
					...asset
				}
				section3subtitleNew {
					internal {
						content
					}
				}
				section3Title
				section3Link {
					linkText
					linkUrl
				}
				section3Cards {
					id
					title
					link
					linkText
					subtitle {
						subtitle
					}
					image {
						fluid {
							base64
							tracedSVG
							srcWebp
							srcSetWebp
						}
					}
				}
				customerTestimonials {
					...customerTestimonial
				}
				section4Title
				section4Subtitle {
					section4Subtitle
				}
				section4Link {
					linkUrl
					linkText
				}
				section4Image {
					...asset
				}
				usecases {
					homepageImage {
						...asset
					}
					section1Title
				}
				section7Subtitle
				section7Title
				section7Link {
					linkText
					linkUrl
				}
				section7Image {
					...asset
				}
				section7Cards {
					id
					title
					link
					linkText
					subtitle {
						subtitle
					}
					image {
						fluid {
							...GatsbyContentfulFluid
						}
					}
				}
				section8Subtitle
				section8Title
				section8Image {
					fluid {
						...GatsbyContentfulFluid
					}
					title
				}
				section9Link
				section9LinkText
				section9Subtitle
				section9Title
				section9Image {
					fluid {
						...GatsbyContentfulFluid
					}
					title
				}
				section11Subtitle
				section11Title
				seoTitle
				seoDescription
				seoArticle
				seoImage {
					title
					file {
						url
						details {
							image {
								height
								width
							}
						}
					}
				}
				seoJsonSchema {
					internal {
						content
					}
				}
			}
		}
	}
`;

export default index;
