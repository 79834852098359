import React from 'react';
import SectionTextBox from '../../global/section-textbox/SectionTextBox';
import * as S from './styles';
import WrapperOne from '../../global/wrappers/WrapperOne';
import { setColors } from '../../../../utils/helpers';
import ImageWrapper from '../../global/ImageWrapper/ImageWrapper';
import { IconNext } from '../../global/icons';
import Slider from 'react-slick';
import CustomerStoryFragmentWrapper from '../../global/CustomerStoryFragmentWrapper/CustomerStoryFragmentWrapper';
import SlickCSS from '../../about-us-page/slick-css';
import ImageCardWrapper from '../../ImageCardWrapper/ImageCardWrapper';
import * as ImageCardStyles from '../../ImageCardWrapper/styles';

const sliderSettings = {
	dots: true,
	fade: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	focusOnSelect: true,
	cssEase: 'linear',
};

const AboutChatbots = ({
	section3Subtitle,
	section3Title,
	section3Image,
	section3Link,
	section3Cards,
	customerTestimonials,
	section4Title,
	section4Subtitle,
	section4Image,
	section4Link,
}) => {
	return (
		<>
			<WrapperOne
				maxWidth={'1200px'}
				setBgColor={setColors.solitude}
				disableBottomMargin
			>
				<S.Section>
					<S.Wrapper>
						<SectionTextBox
							title={section3Title}
							subtitle={section3Subtitle}
							largeHeading="true"
							isHomePage
						/>
						{section3Link.linkText && section3Link.linkUrl && (
							<S.LinkWrapper>
								<a href={section3Link.linkUrl} rel="noopener noreferrer">
									<span>{section3Link.linkText}</span>
								</a>
								<IconNext height={14} width={14} />
							</S.LinkWrapper>
						)}
						<S.ImageWrapper>
							<ImageWrapper image={section3Image} />
						</S.ImageWrapper>
					</S.Wrapper>
				</S.Section>
				<S.CardSliderWrapper>
					<SlickCSS />
					<Slider {...sliderSettings}>
						{section3Cards.map(card => (
							<ImageCardStyles.ImageCardWrapper className="imageCardWrapper">
								<ImageCardStyles.Container>
									<ImageCardStyles.ImageCardTitle className="imageCardTitle">
										{card.title}
									</ImageCardStyles.ImageCardTitle>
									<ImageCardStyles.ImageCardSubtitle className="imageCardSubtitle">
										{card.subtitle.subtitle}
									</ImageCardStyles.ImageCardSubtitle>
									{card.link && (
										<ImageCardStyles.ImageCardLinkWrapper className="imageCardLink">
											<a href={card.link} rel="noopener noreferrer">
												<span>{card.linkText}</span>
											</a>
											<IconNext height={14} width={14} />
										</ImageCardStyles.ImageCardLinkWrapper>
									)}
								</ImageCardStyles.Container>
								<ImageCardStyles.ImageContainer className="imageCardContainer">
									<ImageCardStyles.ImageCardImageWrapper
										className="imageCardImageWrapper"
										src={card.image.fluid.srcWebp || card.image.fluid.src}
									/>
								</ImageCardStyles.ImageContainer>
							</ImageCardStyles.ImageCardWrapper>
						))}
					</Slider>
				</S.CardSliderWrapper>
				<S.CardWrapper>
					<ImageCardWrapper cards={section3Cards} />
				</S.CardWrapper>
				<S.CustomerTestimonialWrapper>
					<SlickCSS />
					<Slider {...sliderSettings}>
						{customerTestimonials.map(testimonial => (
							<CustomerStoryFragmentWrapper data={testimonial} paddingTop="0" />
						))}
					</Slider>
				</S.CustomerTestimonialWrapper>
			</WrapperOne>
			<WrapperOne
				maxWidth={'1200'}
				setBgColor={setColors.bottleGreenColor}
				disableBottomMargin
			>
				<S.Section>
					<S.Wrapper>
						<SectionTextBox
							title={section4Title}
							subtitle={section4Subtitle.section4Subtitle}
							largeHeading="true"
							isHomePage
						/>
						{section4Link.linkText && section4Link.linkUrl && (
							<S.LinkWrapper>
								<a href={section4Link.linkUrl} rel="noopener noreferrer">
									<span>{section4Link.linkText}</span>
								</a>
								<IconNext height={14} width={14} />
							</S.LinkWrapper>
						)}
						<S.ImageWrapper>
							<ImageWrapper
								image={section4Image}
								imageStyles={{
									maxWidth: '1200px',
									margin: '0 auto',
									display: 'block',
								}}
							/>
						</S.ImageWrapper>
					</S.Wrapper>
				</S.Section>
			</WrapperOne>
		</>
	);
};

export default AboutChatbots;
