import styled from 'styled-components';
import {
	setColors,
	setFontFamily,
	setFontWeight,
	setFontSize,
	mediaQuery,
} from '../../../utils/helpers';

export const ImageCardWrapper = styled.div`
	position: relative;
	width: 100%;
	max-width: 585px;
	max-height: 500px;
	background: ${props =>
		props.BgColor ||
		`linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), ${setColors.themeBlue}`};
	box-shadow: 4px 4px 8px rgba(16, 16, 16, 0.05);
	border-radius: 10px;
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	justify-content: space-between;

	${mediaQuery.mobileM`
		justify-content: unset;
	`}
`;

export const Container = styled.div`
	display: flex;
	align-items: flex-start;
	padding: 30px;
	flex-direction: column;
`;

export const ImageCardTitle = styled.div`
	font-size: ${setFontSize.mediumSmall};
	font-weight: ${setFontWeight.bold};
	font-family: ${setFontFamily.black};
	text-align: left;
	font-style: normal;
	color: ${setColors.lightBlack};

	${mediaQuery.laptopML`
		font-size: ${setFontSize.smaller};
	`}
`;

export const ImageCardSubtitle = styled.div`
	font-size: ${setFontSize.tiny};
	font-weight: ${setFontWeight.normal450};
	font-family: ${setFontFamily.book};
	text-align: left;
	font-style: normal;
	color: ${setColors.lightBlack};
	margin-top: 10px;
	line-height: 150%;

	${mediaQuery.laptopML`
		font-size: ${setFontSize.mediumTiny};
	`}

	${mediaQuery.laptopSM`
		font-size: ${setFontSize.littleTiny};
	`}

	${mediaQuery.tabletL`
		font-size: ${setFontSize.tiny};
	`}

	${mediaQuery.tabletXS`
		font-size: ${setFontSize.mediumTiny};
	`}
`;

export const ImageCardLinkWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${setColors.themeBlue};
	text-decoration: none;
	font-size: ${setFontSize.tiny};
	font-weight: ${setFontWeight.extraBold};
	font-family: ${setFontFamily.book};
	margin-top: 10px;

	span {
		color: ${setColors.themeBlue};
	}

	a {
		margin-right: 10px;
		text-decoration: none;
	}

	a:visited {
		color: ${setColors.themeBlue};
	}

	${mediaQuery.laptopML`
		font-size: ${setFontSize.mediumTiny};
	`}

	${mediaQuery.laptopSM`
		font-size: ${setFontSize.littleTiny};
	`}

	${mediaQuery.tabletL`
		font-size: ${setFontSize.tiny};
	`}

	${mediaQuery.tabletXS`
		font-size: ${setFontSize.mediumTiny};
	`}
`;

export const ImageCardImageWrapper = styled.img`
	width: 100%;
	height: 100%;
`;

export const ImageContainer = styled.div`
	max-height: 60%;
	max-width: 75%;
	overflow: hidden;
	margin-top: 40px;

	${mediaQuery.mobileL`
		margin-top: 20px;
	`}
`;
