import React from 'react';
import * as S from './styles';
import Button from '../../global/Button';
import { checkLink } from '../../../../utils/helpers';
import ImageWrapper from '../../global/ImageWrapper/ImageWrapper';

const generateButton = (url, text, outline) => {
	const urlType = checkLink(url);
	if (urlType === 2) {
		return (
			<Button to={`${url}/`} className="heroButton" outline={outline}>
				{text}
			</Button>
		);
	} else if (urlType === 1) {
		return (
			<Button
				as="a"
				href={url}
				target="_self"
				className="heroButton"
				outline={outline}
			>
				{text}
			</Button>
		);
	} else {
		return (
			<Button
				as="a"
				href={url}
				target="_blank"
				className="heroButton"
				rel="noopener noreferrer"
				outline={outline}
			>
				{text}
			</Button>
		);
	}
};

const Header = ({
	section1Link1,
	section1Link2,
	section1Linktext1,
	section1Linktext2,
	section1Subtitle,
	section1Title,
	heroSectionMedia,
}) => {
	return (
		<S.Header>
			<section style={{ marginBottom: '30px' }}>
				<S.Section isHomePageHeader>
					<h1 dangerouslySetInnerHTML={{ __html: section1Title }}></h1>
					<p dangerouslySetInnerHTML={{ __html: section1Subtitle }}></p>
				</S.Section>
				<S.ButtonHolder>
					{section1Link1 &&
						section1Linktext1 &&
						generateButton(section1Link1, section1Linktext1, false)}
					{section1Link2 &&
						section1Linktext2 &&
						generateButton(section1Link2, section1Linktext2, true)}
				</S.ButtonHolder>
				<S.ImageHolder>
					<ImageWrapper
						image={heroSectionMedia}
						imageStyles={{
							margin: '0 auto',
							maxWidth: '1200px',
							width: '100%',
						}}
					/>
				</S.ImageHolder>
			</section>
		</S.Header>
	);
};

export default Header;
