import styled from 'styled-components';

export const Wrapper = styled.section`
	display: grid;
	justify-items: center;

	#feature-section {
		padding-top: 0px;
		margin: 60px 0;
	}
`;

export const Content = styled.div``;
