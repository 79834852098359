import React from 'react';
import FeatureSnippet from '../../global/feature-snippet/FeatureSnippet';
import * as S from './styles';

const Features = ({
	section9Link,
	section9LinkText,
	section9Subtitle,
	section9Title,
	section9Image,
}) => {
	return (
		<S.Wrapper>
			<FeatureSnippet
				link={section9Link}
				linkText={section9LinkText}
				image={section9Image}
				title={section9Title}
				subtitle={section9Subtitle}
			/>
		</S.Wrapper>
	);
};

export default Features;
